@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  background-color: #1D1D20 ;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  
}


.test2 {
  background-color: #1D1D20;
}


.gradient-background {
  background: linear-gradient(300deg,#e55dd7,#a14bf1);
  background-size: 120% 120%;
  animation: gradient-animation 6s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.card-background {
  background: linear-gradient(100deg,#cf1fbd,#8400ff);
  background-size: 150% 120%;
  animation: card-animation 6s ease infinite;
}

@keyframes card-animation {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 10%;
  }
  100% {
    background-position: 0% 20%;
  }
}


.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: linear-gradient(300deg, #cf1fbd, #8400ff);
  background-size: 120% 120%;
  animation: gradient-border-animation 6s ease infinite;
  border-radius: inherit;
}

@keyframes gradient-border-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-border-demo::before {
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}



@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  animation: fadeIn 1.5s;
}